<template>
    <div class="issue-box w3-white w3-card-4 w3-animate-bottom">
        <div class="padded gray-background flex-container flex-justify flex-align-center">
            <strong class="w3-margin-0">Report record set issue</strong>
            <span class="clickable w3-hover-text-red" @click="emitEvent('cancel')">
                <i class="fas fa-times"></i>
            </span>
        </div>
        <div class="padded">
            <slot></slot>
        </div>
        <div class="w3-right-align dark-background">
            <button class="w3-button" :class="{'w3-blue w3-hover-green':enablesubmit, 'w3-gray': !enablesubmit}"
                    @click="emitEvent('submit')" :disabled="!enablesubmit">Submit</button>
            <button class="w3-button w3-black" @click="emitEvent('cancel')">Cancel</button>
        </div>
    </div>
</template>

<script>
/** A floating box with a slot, with submit and cancel events 
    Props:
        title
        enablesubmit

    Events
        submit
        cancel
*/

    
export default {
    props:{
        title:{
            type: String,
            default:""
        },
        enablesubmit:{
            type: Boolean,
            default: true
        }
    },
    methods:{
        emitEvent(name){
            this.$emit(name);
        }
    }
}
</script>

<style scoped>
.issue-box{
    position: fixed;
    bottom: 1rem;
    right: 1rem;
    width: 30rem;
    max-width: calc(100vw - 2rem);
}

@media  screen and (max-width : 800px){

    .issue-box{
        width: 80vw;
    }
}

</style>