<template>
<div class="page-center padded">

    <h1>Search</h1>

    <!-- Search box -->
    <div class="search-fields padded with-gap no-select flex-wrap gray-background">
        <div>
            <strong>Country</strong><mandatory></mandatory>
            <country-list @change="changeCountry()"></country-list>
        </div>

        <div>
            <strong>County</strong><mandatory></mandatory>
            <select class="w3-input w3-white w3-border" 
                    v-model="$store.state.search.county"
                    placeholder="Select the county">
                <option value="">Select the county...</option>
                <option v-for="item in $store.state.counties" :value="item.county" :key="item.county">{{item.county}}</option>
            </select>
        </div>

        <div>
            <strong>Town</strong><mandatory></mandatory>
            <input type="text" 
                    v-model="$store.state.search.town"
                    @keydown.enter="newSearch()"
                    class="w3-input w3-white w3-border" 
                    placeholder="Enter the name of the town">
        </div>

       
        <div>
            <strong>Year, from</strong>
            <input type="number" class="w3-input w3-white w3-border" min="0" v-model="$store.state.search.year_from">
        </div>

        <div>
            <strong>Year, to</strong>
            <input type="number" min="0" 
                                class="w3-input w3-white w3-border" 
                                :class="{'w3-text-red': $store.state.search.year_to<$store.state.search.year_from}"                                 
                                v-model="$store.state.search.year_to">
        </div>

        <div>
            <strong>Record type</strong>
            <div class="record-type-box">

                <span class="no-wrap">
                    <input id="chkbirth" class="w3-check" type="checkbox" v-model="search.births">
                    <label for="chkbirth" class="clickable"> Birth/Bap</label>
                </span>

                <span class="no-wrap">
                    <input id="chkmarriages" class="w3-check" type="checkbox" v-model="search.marriages">
                    <label for="chkmarriages" class="clickable"> Marriages</label>
                </span>

                <span class="no-wrap">
                    <input id="chkdeaths" class="w3-check" type="checkbox" v-model="search.deaths">
                    <label for="chkdeaths" class="clickable"> Deaths</label>
                </span>

                <span class="no-wrap">
                    <input id="chkburials" class="w3-check" type="checkbox" v-model="search.burials">
                    <label for="chkburials" class="clickable"> Burials</label>
                </span>
            </div>
        </div>

        <div>
            <mandatory></mandatory> Mandatory fields
        </div>

        <div></div>

        <div class="w3-right-align">
            <button class="w3-button w3-blue" @click="newSearch()" :disabled="!valid_search">
                    Search
            </button>
            <button class="w3-button w3-black" @click="resetSearch()">
                Reset
            </button>
        </div>
    </div>

    <!-- Search results -->
    <div v-show="has_results">
        <search-results @search="searchRecords" 
                        @first="firstPage" 
                        @prev="prevPage" 
                        @next="nextPage" 
                        @last="lastPage" 
                        @order="orderResults" 
                        @report="reportIssue">
        </search-results>
    </div>

    <!-- No results -->
    <div v-show="!has_results && !working" class="semi-padded">
        <p class="align-center">
            There are no results to show.
        </p>
    </div>

    <!-- Report issue -->
    <floating-form title="Report record set issue" v-if="show_issue_box"
                    :enablesubmit="issue_note.length>10 && validateEmail(issue_email)"
                    @submit="submitIssueReport"
                    @cancel="cancelIssueReport">

        <div><strong>Title: </strong>{{selected_record.info}}</div>
        <div><strong>Collection: </strong>{{selected_record.collection}}</div>
        <div><strong>Years: </strong>{{selected_record.fromyear}}-{{selected_record.toyear}}</div>
        <br>
        <input  type="email" 
                v-model="issue_email" 
                placeholder="Enter your email (optional, we'll notify you when resolved)" 
                class="w3-input w3-border"
                :class="{'w3-text-red': !validateEmail(issue_email)}"
                >
        <limit-entry v-model="issue_note" placeholder="Enter a short description of the issue (mandatory)"></limit-entry>

    </floating-form>

</div>  
</template>

<script>
import searchService from "@/services/searchService.js";
import contactService from "../services/contactService.js";
import SearchResults from "@/components/tables/searchResults.vue";
import CountryList from "../components/lists/countryList.vue";
import FloatingForm from "../components/utils/floatingform.vue";

export default {
    data(){return{
        country:"",
        working: false,
        show_issue_box: false,
        selected_record:{},
        issue_note:"",
        issue_email:""
    }},
    computed:{
        search(){
            return this.$store.state.search;
        },
        countries(){
            return this.$store.state.countries;
        },
        valid_search(){
            let s=this;
            return s.$store.state.search.country.length >0 && 
                    s.$store.state.search.county.length>0 &&
                    s.$store.state.search.town.length>1 &&
                    s.$store.state.search.year_from<=s.$store.state.search.year_to
                    ;
        },
        has_results(){
            return this.$store.state.results.total_records>0;
        },
        max_pages(){
            let s=this,
                results=s.$store.state.results
            ;
            if(results.total_records && results.page_size){
                return Math.ceil(results.total_records / results.page_size);
            }else{
                return 1;
            }
        },
        results_data(){
            return this.$store.state.results.data;
        }
    },
    created(){
        let s=this;
        if(s.$store.state.search.country!=""){
            s.loadCounties();
        }
    },
    mounted(){
        
    },
    methods:{
        changeCountry(){
            let s=this
                // search=s.$store.state.search
            // ;
            // search.county="";
            // search.town="";
            s.loadCounties();
        },
        loadCounties(){
            let s=this;
            s.$notifications.showProgress("Loading counties...");      
            searchService.getCounties(s.$store.state.search.country).then(data=>{
                s.$store.commit("setCounties", data);
                if(data.length==1){
                    // There is only one option
                    s.$set(s.$store.state.search, "county", data[0].county);
                }
            },()=>{
                // s.$store.state.search.county="";
                s.$set(s, "counties", []);
            }).finally(()=>{
                s.$notifications.showInfo("Counties list updated");
                s.$notifications.hideProgress();
            })
        },
        newSearch(){
            let s=this;
            s.$store.state.search.page=1;
            s.searchRecords();
            if(s.$store.state.first_search){
                searchService.registerSearchCounter();
                s.$store.state.first_search=false;
            }
        },
        searchRecords(){
            let s=this;
            s.working=true;
            s.$notifications.showProgress("Retrieving records.")
            searchService.doSearch(s.$store.state.search).then(data=>{
                s.$store.commit("setResults", data);
            }, ()=>{})
            .finally(()=>{
                s.working=false;
                s.$notifications.hideProgress();
            });
        },
        nextPage(){
            let s=this;
            if(s.$store.state.search.page < s.max_pages){
                s.$store.state.search.page++;
                s.searchRecords();
            }
        },
        prevPage(){
            let s=this;
            if(s.$store.state.search.page >1){
                s.$store.state.search.page--;
                s.searchRecords();
            }
        },
        firstPage(){
            this.$store.state.search.page=1;
            this.searchRecords();
        },
        lastPage(){
            this.$store.state.search.page=this.max_pages;
            this.searchRecords();
        },
        clearSearch(){
            this.resetSearch();
        },
        orderResults(field){
            let s=this;
            if(s.search.order!=field){

                // New field, default to ascending order
                s.search.order=field;
                s.search.dir="ASC";

            }else{

                // Same field, toggle
                if(s.search.dir=="ASC"){
                    s.search.dir="DESC";
                }else{
                    s.search.dir="ASC";
                }
            }
            console.log(s.search);
            s.searchRecords();
        },
        resetSearch(){
            this.$store.commit("resetSearch");
        },
        reportIssue(id){
            let s=this,
                record=null
            ;
            record=s.results_data.find(item=>{
                return item.id==id;
            });
            if(record){
                s.$set(s, "selected_record", record);
                s.cancelIssueReport();
                s.show_issue_box=true;
            }
        },
        cancelIssueReport(){
            let s=this;
            s.show_issue_box=false;
            s.issue_note="";
            s.issue_email="";
        },
        submitIssueReport(){
            let s=this;
            s.show_issue_box=false;
            s.$notifications.showProgress("Submitting the report")
            contactService
                .submitIssueReport(s.selected_record.id, s.issue_note, s.issue_email)
                .then(()=>{
                    // Success
                    s.$notifications.showSuccess("Report submitted")
                }, ()=>{
                    // Notify error
                    s.$notifications.showError("The report could not be submitted. Server error.")
                }).finally(()=>{
                    s.$notifications.hideProgress();
                    s.cancelIssueReport()
                });
        },
        validateEmail(email){
            return email.length?contactService.validateEmail(email):true;
        }
    },
    components:{
        SearchResults,
        CountryList,
        FloatingForm
    }
}
</script>

<style scoped>
.search-fields{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr) auto;
}
label{
        margin-right: 1rem;
}

.working-box{
    display: block;
    position: fixed;
    left:50%;
    bottom:30vh;
    transform: translateX(-50%);
    text-align: center;
    padding: 4rem 8rem;
    box-shadow: 0 1rem 1rem rgba(20,20,20,0.2);
}

.record-type-box{
    display: grid;
    grid-template-columns: repeat(4,1fr);
    gap: 0.6rem;
}



@media  screen and (max-width : 800px){

    .search-fields{
        grid-template-columns: 1fr;
        grid-template-rows: auto;
    }

    label{
        margin-right: 8px;
    }

    .record-type-box{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }
}

</style>