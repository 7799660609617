<template>
<div class="w3-animate-opacity">
    <!-- Filter controls -->
    <div class="semi-padded flex-container flex-space-evenly flex-align-center filter-controls">

        <div class="no-wrap w3-right-align flex-container flex-align-center">
            <input type="text" placeholder="Filter current page..." class="w3-input w3-border-top w3-border-left w3-border-bottom w3-white" v-model="filter">
            <button class="w3-button w3-light-gray w3-border" @click="filter=''">
                <i class="fas fa-fw fa-times"></i>
            </button>
            <span v-show="filter.length>0" class="w3-margin-left"> [Found {{filtered_results.length}} on this page]</span>
        </div>

        <div class="align-center flex-grow no-wrap no-select">                            
            <button class="w3-button" @click="emitEvent('first')"><i class="fas fa-fw fa-step-backward"></i>First</button>
            <button class="w3-button" @click="emitEvent('prev')"><i class="fas fa-fw fa-backward"></i>Prev</button>
            <button class="w3-button" @click="emitEvent('next')">Next <i class="fas fa-fw fa-forward"></i></button>
            <button class="w3-button" @click="emitEvent('last')">Last <i class="fas fa-fw fa-step-forward"></i></button>
        </div>
        
        <div class="filter-box-paging no-wrap flex-container flex-align-center no-select">
            <div class="no-wrap flex-container flex-align-center">
                <span>Page</span> 
                <select class="w3-input clickable w3-white no-outline" 
                        v-model="$store.state.search.page" 
                        @change="emitEvent('search')">
                    <option v-for="i in max_pages" :key="i">{{i}}</option>
                </select>            
            </div>
            <div class="side-padded flex-container flex-align-center"> 
                <span>Size </span>
                <select class="w3-input clickable w3-white no-outline"
                        v-model="$store.state.search.page_size"
                        @change="changePageSize()"
                        >
                    <option v-for="i in 5" :key="i" :value="i*20">{{i*20}}</option>
                </select>
            </div>
            <div class="flex-container flex-align-center no-wrap">
                <span>Results </span>
                <strong class="w3-input">{{results.total_records}}</strong>
            </div>
        </div>
    </div>

    <!-- Results box -->
    <div class="table-wrapper full-width">
        <table class="w3-table w3-striped full-width results-table">
            <thead class="dark-background no-wrap no-select">
                <tr>
                    <th class="clickable w3-hover-blue no-wrap" @click="emitEvent('order', 'info')">
                        Title <span class="fa-stack">
                                <i class="fas fa-sort w3-text-gray fa-stack-1x"></i>
                                <i class="fas fa-sort-up fa-stack-1x"  v-show="sort_field=='info' && is_sort_up"></i>
                                <i class="fas fa-sort-down fa-stack-1x" v-show="sort_field=='info' && is_sort_down"></i>
                            </span>
                    </th>
                    <th class="clickable w3-hover-blue no-wrap" @click="emitEvent('order', 'collection')">
                        Collection <span class="fa-stack">
                                <i class="fas fa-sort w3-text-gray fa-stack-1x"></i>
                                <i class="fas fa-sort-up fa-stack-1x" v-show="sort_field=='collection' && is_sort_up"></i>
                                <i class="fas fa-sort-down fa-stack-1x" v-show="sort_field=='collection' && is_sort_down"></i>
                            </span>
                    </th>
                    <th class="clickable w3-hover-blue no-wrap align-center" @click="emitEvent('order', 'fromyear')">
                        Years <span class="fa-stack">
                                <i class="fas fa-sort w3-text-gray fa-stack-1x"></i>
                                <i class="fas fa-sort-up fa-stack-1x" v-show="sort_field=='fromyear' && is_sort_up"></i>
                                <i class="fas fa-sort-down fa-stack-1x" v-show="sort_field=='fromyear' && is_sort_down"></i>
                            </span>
                    </th>
                    <th class="align-center">Record type</th>
                    <th>Website</th>
                    <th class="clickable w3-hover-blue no-wrap" @click="emitEvent('order', 'cost')">
                        Cost <span class="fa-stack">
                                <i class="fas fa-sort w3-text-gray fa-stack-1x"></i>
                                <i class="fas fa-sort-up fa-stack-1x" v-show="sort_field=='cost' && is_sort_up"></i>
                                <i class="fas fa-sort-down fa-stack-1x" v-show="sort_field=='cost' && is_sort_down"></i>
                            </span>
                    </th>
                    <th></th>
                </tr>
            </thead>
            <tbody name="result-items" is="transition-group">
                <tr v-for="item in filtered_results" :key="item.id">
                    <td>
                        {{item.info}}
                    </td>
                    <td>
                        <span>{{item.collection}}</span>
                    </td>
                    <td class="no-wrap align-center">
                        <span v-show="item.fromyear">{{item.fromyear}}</span>
                        <span>-</span>
                        <span v-show="item.toyear">{{item.toyear}}</span>
                    </td>
                    <td class="record-type no-wrap align-center">
                        <strong v-show="item.births==1">B</strong><span v-show="item.births==0">-</span>
                        <strong v-show="item.marriages==1">M</strong><span v-show="item.marriages==0">-</span>
                        <strong v-show="item.deaths==1">D</strong><span v-show="item.deaths==0">-</span>
                        <strong v-show="item.burrials==1">Bu</strong><span v-show="item.burrials==0">-</span>
                    </td>
                    <td class="truncate">
                        <a class="no-wrap" :href="item.website" target="_blank">
                            {{ extractDomain(item.website)}} <i class="fas fa-fw fa-external-link-square-alt"></i>
                        </a>
                    </td>
                    <td>
                        {{item.cost}}
                    </td>
                    <td class="clickable w3-hover-red w3-text-blue-gray align-center" @click="emitEvent('report', item.id)">
                        <span>
                            <i class="fas fa-exclamation-triangle"></i>
                        </span>
                    </td>
                </tr>
                    
            </tbody>
            <tfoot class="dark-background">
                <tr>
                    <td colspan="7" class="align-center">
                        Displaying {{filtered_results.length}}
                    </td>
                </tr>                
            </tfoot>
        </table>        
    </div>

    <div class="align-center flex-grow no-wrap semi-padded">                            
        <button class="w3-button" @click="emitEvent('first')"><i class="fas fa-fw fa-step-backward"></i>First</button>
        <button class="w3-button" @click="emitEvent('prev')"><i class="fas fa-fw fa-backward"></i>Prev</button>
        <button class="w3-button" @click="emitEvent('next')">Next <i class="fas fa-fw fa-forward"></i></button>
        <button class="w3-button" @click="emitEvent('last')">Last <i class="fas fa-fw fa-step-forward"></i></button>
    </div>
</div>
</template>

<script>
/**
    Displays the search results, and includes the filtering. It emits events for paging.

    Props:
    Events:

        search()
        first()
        prev()
        next()
        last()
        order(field)
        report(id)
 */
export default {
    props:{

    },
    data(){return {
        filter:""
    }},
    computed:{
        results(){
            return this.$store.state.results;
        },
        max_pages(){
            let s=this;
            if(s.results.total_records && s.results.page_size){
                return Math.ceil(this.results.total_records / this.results.page_size);
            }else{
                return 1;
            }
        },
        has_results(){
            return this.$store.state.results.total_records>0;
        },
        filtered_results(){
            let s=this;
            if(s.has_results){
                let test=s.filter.toLowerCase();
                return s.results.data.filter(item=>{
                    return item.info.toLowerCase().includes(test) ||
                            item.collection.toLowerCase().includes(test) || 
                            item.website.toLowerCase().includes(test); 
                });
            }else{
                return []
            }
        },
        sort_field(){
            return this.$store.state.search.order;
        },
        is_sort_up(){
            return this.$store.state.search.dir=="ASC";
        },
        is_sort_down(){
            return this.$store.state.search.dir=="DESC";
        }
    },
    methods:{
        emitEvent(event, value=null){
            let s=this;
            if(value){
                s.$emit(event, value);
            }else{
                s.$emit(event);
            }
        },
        extractDomain(url){
            try{
                let domain = new URL(url);
                return domain.hostname.replace("www.","");
            }catch(e){
                return "";
            }
        },
        changePageSize(){
            let s=this;
            s.$store.state.search.page=1;
            s.emitEvent("search");
        }
    }

}
</script>

<style scoped>

table{
    max-width: 100vw;
}

th:nth-child(1){width: 31%;}
th:nth-child(2){width: 22%;}
th:nth-child(3){width: 09%;}
th:nth-child(4){width: 09%;}
th:nth-child(5){width: 19%;}
th:nth-child(6){width: 06%;}
th:nth-child(7){width: 04%;}
th{
    vertical-align: middle;
}

.record-type span,
.record-type strong{
    display: inline-block;
    width: 1.4rem;
    text-align: center;
}

.result-items-move{
    transition: transform 300ms;
}

@media  screen and (max-width : 800px){


    .table-wrapper{
        overflow: auto;
    }

    .truncate {
        display: inline-block;
        width: 5rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        }

    .filter-controls{
        display: grid;
        grid-template-columns: 1fr;
        gap: 1rem;
    }

    .filter-box-paging{
        grid-row: 2;
    }
    
}

</style>