import apiService  from "./apiService.js";

export default {
    validateEmail(email){
        var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    },
    submitIssueReport(id, note="", email=""){
        return this.requestSendEmail("issue", {id, note, email});
    },
    requestSendEmail(type, payload={}){
        let uri=`/contacts/${type}`;
        return apiService.postAPI(uri, payload);
    }

}